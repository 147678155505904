@font-face {
    font-family: "museo-sans-rounded";
    src: local("museo-sans-rounded"),
        url("../../fonts/Museo/MuseoSansRounded-100.otf") format("opentype");

    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "museo-sans-rounded";
    src: local("museo-sans-rounded"),
        url("../../fonts/Museo/MuseoSansRounded-100Italic.otf") format("opentype");

    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "museo-sans-rounded";
    src: local("museo-sans-rounded"),
        url("../../fonts/Museo/MuseoSansRounded-300.otf") format("opentype");

    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "museo-sans-rounded";
    src: local("museo-sans-rounded"),
        url("../../fonts/Museo/MuseoSansRounded-300Italic.otf") format("opentype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "museo-sans-rounded";
    src: local("museo-sans-rounded"),
        url("../../fonts/Museo/MuseoSansRounded-500.otf") format("opentype");

    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "museo-sans-rounded";
    src: local("museo-sans-rounded"),
        url("../../fonts/Museo/MuseoSansRounded-500Italic.otf") format("opentype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "museo-sans-rounded";
    src: local("museo-sans-rounded"),
        url("../../fonts/Museo/MuseoSansRounded-700.otf") format("opentype");

    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "museo-sans-rounded";
    src: local("museo-sans-rounded"),
        url("../../fonts/Museo/MuseoSansRounded-700Italic.otf") format("opentype");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "museo-sans-rounded";
    src: local("museo-sans-rounded"),
        url("../../fonts/Museo/MuseoSansRounded-900.otf") format("opentype");

    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "museo-sans-rounded";
    src: local("museo-sans-rounded"),
        url("../../fonts/Museo/MuseoSansRounded-900Italic.otf") format("opentype");
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "museo-sans-rounded";
    src: local("museo-sans-rounded"),
        url("../../fonts/Museo/MuseoSansRounded-1000.otf") format("opentype");

    font-weight: 1000;
    font-style: normal;
}

@font-face {
    font-family: "museo-sans-rounded";
    src: local("museo-sans-rounded"),
        url("../../fonts/Museo/MuseoSansRounded-1000Italic.otf") format("opentype");

    font-weight: 1000;
    font-style: italic;
}
